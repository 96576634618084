// extracted by mini-css-extract-plugin
export var column = "UserDashboardEditForm__column__BpC6l";
export var defaultProfilePic = "UserDashboardEditForm__defaultProfilePic__dvCxj";
export var fieldContainer = "UserDashboardEditForm__fieldContainer__rahBf";
export var formRow = "UserDashboardEditForm__formRow__rL381";
export var profilePicContainer = "UserDashboardEditForm__profilePicContainer__z7EvJ";
export var row = "UserDashboardEditForm__row__ye92p";
export var twoColumn = "UserDashboardEditForm__twoColumn__Tkl2t";
export var uploadedImage = "UserDashboardEditForm__uploadedImage__AmNEi";
export var uploader = "UserDashboardEditForm__uploader__WWhfM";
export var uploaderInner = "UserDashboardEditForm__uploaderInner__bvxBI";
export var userForm = "UserDashboardEditForm__userForm__o8V6k";