// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as Rest from "../../Rest.res.js";
import * as Json_Encode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Encode.res.js";

function toJson(userId) {
  return Json_Encode$JsonCombinators.object([[
                "userId",
                ID.toJson(userId)
              ]]);
}

var Req = {
  toJson: toJson
};

function exec(userId) {
  return Rest.$$fetch("/dashboard/users/" + (ID.toString(userId) + "/map_rpm_agencies"), {
              NAME: "Post",
              VAL: toJson(userId)
            }, "Empty", undefined, undefined);
}

export {
  Req ,
  exec ,
}
/* ID Not a pure module */
